.profile-img{
    overflow: hidden;
}
.profile-img img{
    transition: transform .5s ease;
}

.profile-img:hover img{
    transform: scale(1.5);
}

@media screen and (max-width: 850px) {
    .profile-img img{
        height: 600px;
    }
}

@media screen and (max-width: 810px) {
    .img-container{
        display: none;
    }
}