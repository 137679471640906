header{
    margin: 0px;
    padding: 50px;
    /* padding-left: 230px; */
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background-color: #24263B;
    overflow: hidden;
}
.name{
    color: white;
    font-family:  'Rubik', sans-serif;
    font-size: 40px;
    font-weight:900;
    letter-spacing: 0.01em;
    cursor:pointer;
}
.route{
    display:flex;
    flex-direction: row;
    text-decoration: none;
    align-items: center;
}
.navbtn{
    margin-right: 50px;
    color: white;
    font-family: "Poppins Regular";
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.005em;
}
.navbtn:hover{
    text-decoration: none;
    color: #F57F17;
}
@media screen and (max-width:810px) {
    .route{
        display: none;
    }
    header{
        flex-direction: row-reverse;
        justify-content: space-between;
        padding-left: 5%;
        padding-right: 30%;
    }
}
@media screen and (max-width:954px) {
    .navbtn{
        margin-right: 20px;
        color: white;
        font-family: "Poppins Regular";
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0.005em;
    }
}

@media screen and (max-width:447px)
{
    header{
        justify-content: space-evenly;
        padding: 10px;
    }
}