.resume-internal {
  display: flex;
  flex-direction: row;
  width: auto;
}

.resume-left-internal {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
/* .resume-internal{

} */
.left-column {
  display: flex;
  flex-direction: column;
}
.left-left-resume {
  display: flex;
  flex-direction: column;
  padding: 0px 30px 30px 30px;
  width: 60%;
}
.resume-right-internal {
  margin-right: 50%;
  font-family: "Rubik", sans-serif;
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 1px;
}

.line-space {
  border: 0.5px solid #F57F17;
  height: 0px;
  margin-bottom: 70px;
}

.year-resume,.left-left-para{
    font-family: "Rubik", sans-serif;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1px;
}
.year-resume{
  width: max-content;
}
.resume-left-internal{
  box-shadow: none;
  transition: box-shadow 0.4s ease-in-out;
  cursor: pointer;
}

.resume-left-internal:hover{
  box-shadow: rgba(0, 0, 0, 0.5) 0px 10px 15px -3px, rgba(0, 0, 0, 0.09) 0px 4px 6px -2px;
}
.left-left-heading{
    font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 10px;
}

@media screen and (max-width:945px) {
  .resume-internal{
    flex-direction: column;
  }
}