footer{
    width: 100%;
    margin: 0px;
}

.footerbottom{
    padding: 80px 0px;
    background-color: #24263B;
    color: whitesmoke;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    font-family: 'Mulish', sans-serif;
    font-size: 16px;
    line-height: 2;
    letter-spacing: .5px;
    font-weight: 400;
}

@media screen and (max-width:850px) {
    .footerbottom{
        display: flex;
        flex-direction: column;
        align-items: center;
        word-wrap: break-word;
    }
}