.Contact-img-container{
    display: flex;
    flex-direction: column;
}

.Contact-img-container h4{
    font-family: 'Rubik', sans-serif;
    font-size: 16px;
    font-weight: 200;
    letter-spacing: .5px;
    color: rgb(125, 125, 123);
    opacity: 0.8;
}

.contact-head h2{
    font-family: 'Inter', sans-serif;
    font-size: 30px;
    font-weight:900;
    letter-spacing: 3px;
    color: whitesmoke;
    margin-bottom: 20px;
    margin-left: 15px;
}
.space-up{
    margin-top: 100px;
    letter-spacing: 3px;
}

#icon-contact{
    margin-left: 20px;
}
.content-img{
    margin-bottom: 100px;
}

@media screen and (max-width: 810px)
{
    .space-up{
        margin-top: 0px;
    }
    .content-img{
        margin-bottom: 0px;
    }
    .content-img img{
        width: 100%;
    }
}