.contact-container{
    display: flex;
    flex-direction: row;
    background-color: #24263B ;
    padding: 10px;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 100px;
    border-radius: 15px;
    align-items: center;
    /* box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px; */
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
    transition: box-shadow 0.3s ease-in-out;
}
.contact-container:hover{
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
.decorative-line-2{
    border: 0.5px solid black;
    height: 0%;
    width: 150px;
    margin-bottom: 60px;
}
@media screen and (max-width: 1029px)
{
    .contact-container{
       width: 90%;
    }
}

@media screen and (max-width: 810px) {
    .contact-container{
        flex-direction: column;
    }
}