.contact-detail-container{
    width:500px ;
    padding: 20px;
}

.contact-white-container{
    background-color: white;
    border-radius: 10px;
    width: auto;
    margin-top: 50px;
    padding: 20px;
    
}
.column-container{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.input-box{
    margin-bottom: 30px;
    border-radius: 10px;
    border: 1px solid rgb(244, 242, 242);
    background-color: rgb(244, 242, 242);
    font-size: smaller;
    width: 100%;
}
#box-type{
    height: 100px;
}

.submit-btn{
    border-radius: 10px;
    background-color:#24263B ;
    color:whitesmoke;
    padding: 5px;
    width: 30%;
    font-family: 'Rubik', sans-serif;
    font-size: 16px;
    font-weight: 200;
    transition: all 0.5s;
}

.submit-btn:hover{
    border: 2px solid #F57F17 ;
    color: #F57F17 ;
}

@media screen and (max-width: 890px) {
    .submit-btn{
        width: 50%;
    }
}

@media screen and (max-width: 810px) {
    .contact-detail-container{
        width: 100%;
        padding: 5px;
    }
}