.profile-container{
    display: flex;
   align-items: center;
   justify-content: center;
   height: 10%;
   min-height: fit-content;
   
}
.profile-parent {
    display: flex;
    align-items: center;
    color:aliceblue ;
}

.profile-details-name{
    font-size: 24px;
    font-family: "Poppins SemiBold";
}
.profile-details-role{
    display: flex;
    flex-direction: column;
    margin:14px 0 24px 0;
}

.profile-details-role h1{
    font-size: 40px;
    font-family: cursive;
}
.profile-details{
    text-align: center;
}

.primary-text{
    color: (--white);
}
.highlight-text{
    color: var(--dark-orange);
}
.profile-role-tagline{
    font-size: 19px;
    margin: 5px 0 0 0;
    font-family: "Poppins Light";
}
.profile-options .highlighted-btn{
    margin: 0 0 0 28px;
}
.profile-options .highlighted-btn:hover{
    margin: 0 0 0 28px;
    background-color: cornsilk;
    color: #111;
}
.profile-picture{
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 0px 0px var(--white);
    border-radius: 50%;
    height: 380px ;
    width: 380px;
    margin:  0 0px 0 100px;
}

.profile-picture-background{
    height: 92%;
    width: 92%;
    background-image: url("../../assets/Home/achyut.jpg");
    background-size: cover;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
}
.profile-picture-background:hover{
    transform: scale(1.07);
    transition: 1s ease-out;
}
.colz .colz-icon i{
    color: white;
    padding: 8px;
    padding-left: 0px;
}
.colz .colz-icon i:hover{
    transform: scale(1.5) ;
    transition: 0.5s ease-in-out;
}

@media screen and (max-width:810px) {
    .profile-parent{
        flex-direction: column-reverse;
        margin: 40px;
    }
    .profile-parent{
        display: flex;
        align-items: center;
        color: #f0f8f0;
    }
    .profile-details{
        text-align: center;
    }
    .profile-picture{
        margin: 0px;
        height: 300px;
        width: 300px;
    }
    .profile-role-tagline{
        word-wrap: break-word;
    }
}

@media screen and (max-width:430px) {
    .profile-options{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }
    .profile-options .highlighted-btn{
        margin-left: 0px;
        margin-top: 10px;
    }
}