.testi-container{
    display:flex ;
    flex-direction: column;
    justify-content: space-between ;
    /* height: 50vh !important; */
    min-height: 500px;
    background-color:#24263B ;
    overflow: hidden;
}
.decorative-line-3{
    border: 0.5px solid black;
    height: 0%;
    width: 250px;
    margin-bottom: 60px;
}