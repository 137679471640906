.detail-container{
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: fit-content;
    justify-content: space-between;
}
.detail-para{
    word-wrap: break-word;
    width: 400px;
}
@media screen and (max-width:850px) {
    .detail-para{
        width: 100%;
    }
}