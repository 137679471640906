.externalBarDiv{
    background-color: whitesmoke;
    width: 50%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    margin-bottom: 40px;
    transition: width 0.5s ease-in-out;
}
.externalBarDiv:hover{
     width: 60%;
}
.internalBarDiv{
    display: flex;
    flex-direction: row;
    color: #111;
}
.projectDetails{
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    justify-content: space-around;
}
.projet-name{
    margin-top: 10px;
    font-size: 20px;
    font-weight: 600;
    color: var(--orange);
}
.project-link{
    margin-bottom: 10px;
    width: 20%;
    align-self: flex-end;
    border: none;
    padding: 15px;
    border-radius: 30px;
    background-color: #24263B;
    cursor: pointer;
    color: whitesmoke;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.project-link p{
    margin-right: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.project-link:hover{
    -webkit-transform: translateY(-1px);
	transform: translateY(-1px);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.discription{
   word-wrap: break-word;
   text-overflow: ellipsis;
   font-size: 16px;
   font-weight: 500;
   font-family:'Roboto', sans-serif;
   max-height: 100px;
   height: 100%;
   overflow: hidden;
}
.duration{
  opacity: 0.5;
}
.webLink{
    border: none;
    height: auto;
    width: 50%;
    /* margin-right: 10px; */
}
.webLink img{
    height: 100%;
    object-fit:fill;
    width: 100%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}

@media only screen and (max-width : 1250px) {
    .project-link{
        width: 40%;
    }
}

@media screen and (max-width: 945px) {
    .externalBarDiv{
        width: 70%;
        border-radius: 10px;
        align-self: center;
    }
    .internalBarDiv{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }
    .project-link{
        align-self: center;
    }
    .webLink img{
        border-radius: 10px;
        width: 100%;
        height: 250px;
        overflow: hidden;
    }
    .projet-name,.duration,.discription{
        align-self: center;
    }
}