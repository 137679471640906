.about-container{
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
.external-contain{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 200px;
}
.header{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.header h2{
    font-family:  'Mulish', sans-serif;
    font-size: 30px;
    font-weight:900;
    letter-spacing: .005px;
    margin-bottom: 10px;
}

.header h4{
    font-family: 'Rubik', sans-serif;
    font-size: 16px;
    font-weight: 200;
    letter-spacing: .5px;
    color: rgb(56, 56, 55);
    margin-bottom: 10px;
}
.decorative-line{
    border: 0.5px solid black;
    height: 0%;
    width: 100%;
    margin-bottom: 60px;
}
@media screen and (max-width:850px) {
    .about-container{
        width: 90%;
    }
}